import { CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import PropTypes from 'prop-types'
import * as React from 'react';
import { useSigninCheck } from 'reactfire';

export const AuthWrapper = ({ children, fallback }) => {
    const { status, data: signInCheckResult } = useSigninCheck();
    
    if (status === "loading") {
      return (
        <Grid2 container sx={{ height: '100vh' }}>
          <Grid2 xs={12} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color='secondary'/>
          </Grid2>
        </Grid2>
      )
    }

    if (!children) {
      throw new Error('Children must be provided');
    }
  
    if (signInCheckResult.signedIn === true) {
      return children;
    } else {
      return fallback;
    }
};

AuthWrapper.propTypes = {
  children: PropTypes.any,
  fallback: PropTypes.any
}