import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, Button, ButtonBase, Chip, IconButton, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useAuth } from 'reactfire';

// project imports
import FullLogo from 'ui-component/extended/FullLogo'
import ProfileSection from './ProfileSection'

// assets
import { IconMenu2, IconPigMoney, IconWallet } from '@tabler/icons-react'

// store
import { SET_DARKMODE } from 'store/actions'
import FullLogoSection from '../FullLogoSection'

// Hooks
import useApi from 'hooks/useApi'

// API
import creditsApi from 'api/credits'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const customization = useSelector((state) => state.customization)

    const [credits, setCredits] = useState(0)

    const getAvailableCreditsApi = useApi(creditsApi.getAvailableCredits)

    const auth = useAuth();

    const signOutClicked = async () => {
        await auth.signOut()
        navigate('/', { replace: true })
        navigate(0)
    }

    const creditsBtnClicked = () => {
        getAvailableCreditsApi.request()
    }

    useEffect(() => {
        getAvailableCreditsApi.request()
    }, [])
    
    useEffect(() => {
        if (getAvailableCreditsApi.data) {
            try {
                const availableCredits = getAvailableCreditsApi.data.value
                setCredits(availableCredits)
            } catch (e) {
                console.error(e)
            }
        }
    }, [getAvailableCreditsApi.data])

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <IconButton
                    sx={{ 
                        overflow: 'hidden', 
                        display: { xs: 'block', md: 'none' }
                    }}
                    onClick={handleLeftDrawerToggle}
                    size="small"
                >
                    {/* <Avatar> */}
                        <IconMenu2 stroke={1.5} />
                    {/* </Avatar> */}
                </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {/* <FullLogo width={240} /> */}
            <FullLogoSection width={240} />
            <Box sx={{ flexGrow: 1 }} />
            <Button color='secondary' variant='outlined' size='medium' onClick={creditsBtnClicked}>
                Credits: {credits}
            </Button>
            <ProfileSection handleLogout={signOutClicked} username={localStorage.getItem('username') ?? ''} />
        </>
    )
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
}

export default Header