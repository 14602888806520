import { useSelector } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'
import { CircularProgress, CssBaseline, StyledEngineProvider } from '@mui/material'

// routing
import Routes from 'routes'

// defaultTheme
import themes from 'themes'

// project imports
import NavigationScroll from 'layout/NavigationScroll'
import { SignInForm } from 'login2'
import { AuthWrapper } from 'AuthWrapper'

// 3rd party imports
import { AuthProvider, useFirebaseApp, SuspenseWithPerf } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics'

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization)
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);
    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(firebaseApp);

    return (
        <AuthProvider sdk={auth}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<CircularProgress />}>
                            <AuthWrapper fallback={<SignInForm />}>
                                <Routes />
                            </AuthWrapper>
                        </SuspenseWithPerf>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </AuthProvider>
    )
}

export default App
