import PropTypes from 'prop-types'
import SCLogoFull from 'assets/images/Logo/SC-logo-full-1.svg'
import SCLogoFullWhite from 'assets/images/Logo/SC-logo-full.svg'

import { useSelector } from 'react-redux'

// ==============================|| LOGO ||============================== //

const FullLogo = ({ width, color }) => {
    const customization = useSelector((state) => state.customization)

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <img
                style={{ objectFit: 'contain', height: 'auto', width: width? width : 50 }}
                src={color === 'white' ? SCLogoFullWhite : SCLogoFull }
                alt='SuperCanvas Full Logo'
            />
        </div>
    )
}

FullLogo.propTypes = {
    width: PropTypes.number,
    color: PropTypes.string
}

export default FullLogo