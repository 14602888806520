import PropTypes from 'prop-types'
import logo from 'assets/images/flowise_logo.png'
import logoDark from 'assets/images/flowise_logo_dark.png'
import SCLogo from 'assets/images/Logo/SC-logo_round.svg'

import { useSelector } from 'react-redux'

// ==============================|| LOGO ||============================== //

const Logo = ({ width}) => {
    const customization = useSelector((state) => state.customization)

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <img
                style={{ objectFit: 'contain', height: 'auto', width: width? width : 50 }}
                src={customization.isDarkMode ? SCLogo : SCLogo}
                alt='SuperCanvas'
            />
        </div>
    )
}

Logo.propTypes = {
    width: PropTypes.number
}

export default Logo
