import * as React from 'react';
import { useAuth, useUser, SuspenseWithPerf, useSigninCheck } from 'reactfire';
import { GoogleAuthProvider, signInWithPopup, User } from "firebase/auth";
import { Avatar, Box, Button, CssBaseline, FormControlLabel, Grid, Link, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import Google from 'assets/images/social-google.svg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CoverImage from 'assets/images/Landingpage-Image.png'
import FullLogo from 'ui-component/extended/FullLogo';


const signIn = async auth => {
    const provider = new GoogleAuthProvider();
  
    await signInWithPopup(auth, provider);
}

export const SignInForm = () => {
    const auth = useAuth();
    const theme = useTheme()

    return (
        <Grid2 container sx={{ height: '100vh' }}>
            {/* <CssBaseline /> */}
            {/* Image */}
            <Grid2
                xs={false}
                sm={4}
                md={6}
                sx={{
                    backgroundImage: `url(${CoverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />

            {/* Signin form */}
            <Grid2
                xs={12} sm={8} md={6}
                sx={{
                    backgroundColor: '#240a41'
                }}
                display="flex" justifyContent="center" alignItems="center"
            >
                <Stack spacing={1} textAlign='center' >
                    <FullLogo width={400} color='white' />
                    <Typography
                        variant='h4'
                        sx={{
                            pt: 2,
                            color: theme.palette.grey[300]
                        }}
                    >
                        <b>Welcome</b>, let&apos;s build amazing no-code
                    </Typography>
                    <Typography
                        variant='h4'
                        sx={{
                            color: theme.palette.grey[300],
                            pb: 4
                        }}
                    >
                        AI apps via drag and drop.
                    </Typography>

                    <Button
                            fullWidth={true}
                            sx={{
                            fontSize: { md: '1rem', xs: '0.875rem' },
                            fontWeight: 500,
                            my: 10,
                            backgroundColor: theme.palette.grey[50],
                            color: theme.palette.grey[600],
                            textTransform: 'capitalize',
                            '&:hover': {
                                backgroundColor: theme.palette.grey[100]
                            }
                            }}
                            size="large"
                            variant="contained"
                            onClick={() => signIn(auth)}
                        >
                            <img
                            src={Google}
                            alt="google"
                            width="20px"
                            style={{
                                marginRight: '16px',
                                '@media (maxWidth:899.95px)': {
                                marginRight: '8px'
                                }
                            }}
                            />{' '}
                            Sign in with Google
                        </Button>
                </Stack>
            </Grid2>
        </Grid2>
    )
}