import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// material-ui
import { Box, ImageList, ImageListItem, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'


import WorkflowEmptySVG from 'assets/images/workflow_empty.png'

// API
import getResultsApi from 'api/results'

// Hooks
import useApi from 'hooks/useApi'

// const
import { BackdropLoader } from 'ui-component/loading/BackdropLoader'

// ==============================|| Results ||============================== //

const Results = () => {
    const navigate = useNavigate()

    const theme = useTheme()
    const customization = useSelector((state) => state.customization)

    const [isLoading, setLoading] = useState(true)
    const [images, setImages] = useState({})

    const getAllResultsApi = useApi(getResultsApi.getAllResults)

    const goToCanvas = (selectedChatflow) => {
        navigate(`/results`, { state: selectedChatflow })
    }

    useEffect(() => {
        getAllResultsApi.request()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(getAllResultsApi.loading)
    }, [getAllResultsApi.loading])

    return (
        <>
            <ImageList variant="masonry" cols={3} gap={8}>
                {!isLoading &&
                    getAllResultsApi.data &&
                    getAllResultsApi.data.map((url, index) => (
                    <ImageListItem key={index}>
                    <img
                        src={url}
                        alt="result"
                        loading="lazy"
                    />
                    </ImageListItem>
                ))}
            </ImageList>
            {!isLoading && (!getAllResultsApi.data || getAllResultsApi.data.length === 0) && (
                <Stack sx={{ alignItems: 'center', justifyContent: 'center' }} flexDirection='column'>
                    <Box sx={{ p: 2, height: 'auto' }}>
                        <img style={{ objectFit: 'cover', height: '30vh', width: 'auto' }} src={WorkflowEmptySVG} alt='WorkflowEmptySVG' />
                    </Box>
                    <div>No Results Yet</div>
                </Stack>
            )}
            <BackdropLoader open={isLoading} />
        </>
    )
}

export default Results