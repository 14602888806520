// assets
import { IconHierarchy, IconBuildingStore, IconKey, IconHierarchy3, IconAlbum } from '@tabler/icons-react'

// constant
const icons = { IconHierarchy, IconBuildingStore, IconKey, IconHierarchy3, IconAlbum }

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'chatflows',
            title: 'Projects',
            type: 'item',
            url: '/projects',
            icon: icons.IconHierarchy3,
            breadcrumbs: true
        }
        // ,
        // {
        //     id: 'marketplaces',
        //     title: 'Marketplaces',
        //     type: 'item',
        //     url: '/marketplaces',
        //     icon: icons.IconBuildingStore,
        //     breadcrumbs: true
        // }
        ,
        {
            id: 'apikey',
            title: 'API Keys',
            type: 'item',
            url: '/apikey',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'results',
            title: 'Results',
            type: 'item',
            url: '/results',
            icon: icons.IconAlbum,
            breadcrumbs: true
        }
    ]
}

export default dashboard
