import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// material-ui
import { ButtonBase } from '@mui/material'

// project imports
import config from 'config'
import FullLogo from 'ui-component/extended/FullLogo'


// ==============================|| MAIN LOGO ||============================== //

const FullLogoSection = ({ width, color }) => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <FullLogo width={width} color={color} />
    </ButtonBase>
)

FullLogoSection.propTypes = {
    width: PropTypes.number,
    color: PropTypes.string
}

export default FullLogoSection
