import React from 'react'
import App from './App'
import { store } from 'store'
import { createRoot } from 'react-dom/client'

// style + assets
import 'assets/scss/style.scss'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import ConfirmContextProvider from 'store/context/ConfirmContextProvider'
import { ReactFlowContext } from 'store/context/ReactFlowContext'
import { FirebaseAppProvider } from 'reactfire';

const container = document.getElementById('root')
const root = createRoot(container)

const firebaseConfig = {
    apiKey: "AIzaSyACv8XN4L_ljMeeGcuEPfYBL68fFcNI6Js",
    authDomain: "ai-pipeline-app.firebaseapp.com",
    projectId: "ai-pipeline-app",
    storageBucket: "ai-pipeline-app.appspot.com",
    messagingSenderId: "16764068229",
    appId: "1:16764068229:web:b09a058f4b84f206e464ec",
    measurementId: "G-S7GFS3T5PJ"
};

root.render(
    <React.StrictMode>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <Provider store={store}>
                <BrowserRouter>
                    <SnackbarProvider>
                        <ConfirmContextProvider>
                            <ReactFlowContext>
                                <App />
                            </ReactFlowContext>
                        </ConfirmContextProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </Provider>
        </FirebaseAppProvider>
    </React.StrictMode>
)
